import {routes, drawer} from './customerMainRoutes'


// ---------------------------- preorder ---------------------------
const preOrderRoutes = [
  {
    path: "/preOrder/balances",
    name: "ارزهای بخش سفارش گذاری",
    component: () =>
      import(/* webpackChunkName: "see balances" */ "@/views/customer/preOrder/balances.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/preOrder/:coinId/main/:symbol",
    name: "سفارش گذاری",
    component: () =>
      import(/* webpackChunkName: "submit order" */ "@/views/customer/preOrder/main.vue"),
    meta: {
      types: ["authorized"],
    },
  },
];

const preOrderDrawer = [
  {
    title: "سفارش گذاری",
    icon: "mdi-sync",
    to: "/preOrder/balances",
  },
];

// ---------------------------- p2p ---------------------------
const p2pRoutes = [
  {
    path: "/p2p/balances",
    name: "ارزهای بازار حرفه ای",
    component: () =>
      import(/* webpackChunkName: "p2p" */ "@/views/customer/p2pOrder/balances.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/p2p/:coinId/main/:symbol",
    name: "بازار حرفه ای",
    component: () =>
      import(/* webpackChunkName: "p2p" */ "@/views/customer/p2pOrder/main.vue"),
    meta: {
      types: ["authorized"],
    },
  },
];

const p2pDrawer = [
  {
    title: "بازار حرفه ای (P2P)",
    icon: "mdi-sync",
    to: "/p2p/balances",
  }
];


// ---------------------- p2p stablecoins --------------------
const stableCoinP2pRoutes = [
  {
    path: "/stableCoinP2pOrder/balances",
    name: "بازار P2P ویژه",
    component: () =>
      import(/* webpackChunkName: "stable-coin-p2p" */ "@/views/customer/stableCoinP2pOrder/balances.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/stableCoinP2pOrder/:coinId/main/:symbol",
    name: "ثبت سفارش بازار P2P ویژه",
    component: () =>
      import(/* webpackChunkName: "stable-coin-p2p" */ "@/views/customer/stableCoinP2pOrder/main.vue"),
    meta: {
      types: ["authorized"],
    },
  }
];

const stableCoinP2pDrawer = [
  {
    title: "بازار P2P ویژه",
    icon: "mdi-sync",
    to: "/stableCoinP2pOrder/balances",
  }
];

// --------------------- staking ------------------------
const stakingRoutes = [
  {
    path: "/staking/stakeplans",
    name: "پلن های سرمایه گذاری",
    component: () =>
      import(/* webpackChunkName: "staking" */ "@/views/customer/staking/stakeplans.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/staking/stakes/list",
    name: "سرمایه گذاری های من",
    component: () =>
      import(/* webpackChunkName: "staking" */ "@/views/customer/staking/stakeList.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/staking/stakes/add/:stakePlanId",
    name: "سرمایه گذاری جدید",
    component: () =>
      import(/* webpackChunkName: "staking" */ "@/views/customer/staking/addStake.vue"),
    meta: {
      types: ["authorized"],
    },
  },
];

const stakingDrawer = [
  {
    title: "سرمایه گذاری",
    icon: "mdi-currency-usd",
    to: `/staking`,
    badge : 'new',
    badgeColor: '#039BE5',
    children: [
      {
        title: "فرصت های سرمایه گذاری",
        icon: "mdi-circle-small",
        to: `/staking/stakeplans/`,
      },
      {
        title: "سوابق سرمایه گذاری",
        icon: "mdi-circle-small",
        to: `/staking/stakes/list`,
      },
    ]
  },
];

// --------------------- fan club ------------------------
const fanClubRoutes = [
  {
    path: "/fanclub/main",
    name: "باشگاه مشتریان",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/main.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/fanclub/lottery",
    name: "شرکت در قرعه کشی",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/lottery.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/fanclub/lottery/winners",
    name: "اسامی برندگان قرعه کشی",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/lotteryWinners.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/fanclub/festival",
    name: "شرکت در جشنواره",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/festival.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/fanclub/chanceWheel",
    name: "چرخونه شانس",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/chanceWheel.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "fanClub/pointHistory/list",
    name: "سوابق امتیازات من",
    component: () =>
      import(/* webpackChunkName: "fanClub" */ "@/views/customer/fanClub/pointHistory.vue"),
    meta: {
      types: ["authorized"],
    },
  }
];

const fanClubDrawer = [
  {
    title: "باشگاه مشتریان",
    icon: "mdi-account-group",
    to: `/fanClub/main`
  },
];


// --------------------- manual order ----------------------
const manualOrderRoutes = [
  {
    path: "/manualOrder/list",
    name: "سوابق حواله های ارزی",
    component: () =>
      import(/* webpackChunkName: "manualOrder" */ "@/views/customer/manualOrder/manualOrderList.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/manualOrder/add",
    name: "ثبت حواله ارزی جدید",
    component: () =>
      import(/* webpackChunkName: "manualOrder" */ "@/views/customer/manualOrder/addManualOrder.vue"),
    meta: {
      types: ["authorized"],
    },
  },
  {
    path: "/manualOrder/details/:id",
    name: "جزئیات حواله ارزی",
    component: () =>
      import(/* webpackChunkName: "manualOrder" */ "@/views/customer/manualOrder/manualOrderDetails.vue"),
    meta: {
      types: ["authorized"],
    },
  },
];

const manualOrderDrawer = [
  {
    title: "حواله ارزی",
    icon: "mdi-currency-usd",
    to: `/manualOrder`,
    badge : 'new',
    badgeColor: '#039BE5',
    children: [
      {
        title: "ثبت حواله ارزی",
        icon: "mdi-circle-small",
        to: `/manualOrder/add/`,
      },
      {
        title: "سوابق حواله های ارزی",
        icon: "mdi-circle-small",
        to: `/manualOrder/list`,
      },
    ]
  },
];


// --------------------------- methods -----------------------

function  getRoutes(setting) {
  let r = routes;

  if(setting.preOrderEnabled) {
    preOrderRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }
  
  if(setting.p2pEnabled) {
    p2pRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }

  if(setting.stableCoinP2pEnabled) {
    stableCoinP2pRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }

  if(setting.stakingEnabled) {
    stakingRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }

  if(setting.fanClubEnabled) {
    fanClubRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }

  if(setting.manualOrderEnabled) {
    manualOrderRoutes.forEach(item => {
      r[0].children.push(item);
    });
  }

  return r;
}

function getDrawer(setting) {
  let d = drawer;
  

  if(setting.preOrderEnabled) {
    preOrderDrawer.forEach(item => {
      d.splice(2, 0, item); // insert at index 2
    });
  }

  if(setting.p2pEnabled) {
    p2pDrawer.forEach(item => {
      d.splice(3, 0, item); // insert at index 3
    });
  }

  if(setting.stableCoinP2pEnabled) {
    stableCoinP2pDrawer.forEach(item => {
      d.splice(4, 0, item); // insert at index 4
    });
  }

  if(setting.stakingEnabled) {
    stakingDrawer.forEach(item => {
      d.splice(6, 0, item); // insert at index 6
    });
  }

  if(setting.fanClubEnabled) {
    fanClubDrawer.forEach(item => {
      d.splice(6, 0, item); // insert at index 6
    });
  }

  if(setting.manualOrderEnabled) {
    manualOrderDrawer.forEach(item => {
      d.splice(1, 0, item); // insert at index 0
    });
  }

  return d;
}

export { getRoutes, getDrawer };