import Vue from "vue";
import numberMask from '@/plugins/numberMask'

const masks = {
  install(Vue, options) {
    Vue.prototype.$IMASK = IMask;
    Vue.prototype.$numberMask = numberMask;
  },
};

Vue.use(masks);
