import axios from "axios";
import { loggerApi } from "../plugins/logger";
import store from "../store";
import { returnAppConfig } from "../util/getConfigs";

const baseURL = returnAppConfig().api;

let http = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Credentials": true,
  }
});

http.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


http.interceptors.response.use(
  (response) => {
    loggerApi(response);
    return response;
  },
  (error) => {
    loggerApi(error);
 
    if (error.response === undefined) {
      errorMessage = "Please check your internet connectivity!";
      setTimeout(() => {
        store.state.user.logBox = true;
        store.state.user.logText = "لطفا اتصال اینترنت خود را بررسی کنید";
        store.state.user.logErrors = [];
        store.state.user.logStatus = 400;
      }, 200);
    }

    if (error.response.status === 408 || error.code === "ECONNABORTED") {
      return "timeout";
    }

    return Promise.reject(error);
  }
);

export default http;
