import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import http from "./http/http";
import "./plugins"; // import all plugin files which is inside /plugins/index.js file
import { sync } from "vuex-router-sync";
import "./assets/scss/main.scss";
import "./assets/fonts/fonts.scss";


// splide.js styles
import "@splidejs/splide/dist/css/splide.min.css";
// or
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
// import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';

import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

// global components
import VLog from "./components/globals/VLog.vue";
import animatedToman from "./components/globals/animatedToman.vue";
import animatedDollar from "./components/globals/animatedDollar.vue";
import animatedNumber from "./components/globals/animatedNumber.vue";
import kmPagination from "./components/globals/kmPagination.vue";
import helpMessage from "./components/globals/helpMessage.vue";
import openLink from "./components/globals/openLink.vue";
import btnSubmit from "./components/globals/btnSubmit.vue";
import btnCancel from "./components/globals/btnCancel.vue";
import kmDialog from "./components/globals/kmDialog.vue";
import printPage from "./components/globals/print-page.vue";
import PersianDate from "./components/globals/persianDate.vue";

import { returnStoreConfig } from "./util/getConfigs";

Vue.component("v-log", VLog);
Vue.component("animated-toman", animatedToman);
Vue.component("animated-dollar", animatedDollar);
Vue.component("animated-number", animatedNumber);
Vue.component("km-pagination", kmPagination);
Vue.component("help-message", helpMessage);
Vue.component("open-link", openLink);
Vue.component("btn-submit", btnSubmit);
Vue.component("btn-cancel", btnCancel);
Vue.component("km-dialog", kmDialog);
Vue.component("print-page", printPage);
Vue.component("persian-date", PersianDate);

returnStoreConfig();

sync(store, router);

function addUserTokenToService() {
  http.interceptors.request.use(
    (config) => {
      let token = store.getters.getUserInfo.token;
      if (token == null || token == "null" || token == undefined) {
        delete http.defaults.headers.common["Authorization"];
      } else {
        http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (err) => {}
  );
}

let notFoundRoute = {
  path: "",
  component: () => import("@/layouts/empty/Index.vue"),
  children: [
    {
      path: "*",
      component: () =>
        import(/* webpackChunkName: "notFound404" */ "@/views/global/authentication/notFound404.vue"),
      meta: {
        roles: ["any"],
      },
    },
  ],
};

store.dispatch("initial").then(() => {
  addUserTokenToService();
  store
    .dispatch("updateMe")
    .then(() => {
      store.dispatch("makeRoutes").then((res) => {
        router.addRoutes([...store.state.userNew.guard.routes]);
        router.addRoute(notFoundRoute);
      });
    })
    .catch((err) => {
      router.addRoute(notFoundRoute);
    })
    .finally(() => {});
});

Vue.prototype.$http = http;
Vue.config.productionTip = false;

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

window.addEventListener("beforeinstallprompt", (e) => {
  store.commit("setPWAState", false);
  store.commit("setPWAEvent", e);
});

window.addEventListener("storage", () => {
  store.commit("setRecentlyToastFromLocalStorage");
});
