<template>
  <div>
    <span v-show="!realNumber" :class="increase == true ? increaseClass : '' || decrease == true ? decreaseClass : ''">
      {{ animatedNumber }}
    </span>
    <span v-show="realNumber" :class="increase == true ? increaseClass : '' || decrease == true ? decreaseClass : ''">
      {{ $numberMask(number).masked }}
    </span>
  </div>
</template>

<script>
export default {
  name: "animatedNumber",
  props: {
    number: {
      type: Number,
      default: 0,
    },
    animation: {
      type: Boolean,
      default: false,
    },
    increaseClass: {
      type: String,
      default: "",
    },
    decreaseClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      increase: false,
      decrease: false,
      realNumber: true,
      tweenedNumber: 0,
    };
  },
  computed: {
    animatedNumber: function () {
      return this.tweenedNumber.toFixed(0);
    },
  },
  watch: {
    number: function (newValue, oldValue) {
      if (newValue > oldValue) {
        this.increase = true;
      } else if (newValue < oldValue) {
        this.decrease = true;
      }
      setTimeout(() => {
        this.increase = false;
        this.decrease = false;
      }, 3000);

      if (this.animation == true) {
        gsap.to(this.$data, {
          duration: 2,
          tweenedNumber: newValue,
          onStart: () => {
            this.realNumber = false;
            setTimeout(() => {
              this.realNumber = true;
            }, 1000);
          },
        });
      }
    },
  },
};
</script>
