import moment from "jalali-moment";

class Translate {
  constructor(data) {
    this.data = data;
  }

  table(list, headers) {
    let sheet = [];
    let column;
    let row = [];
    headers.forEach((itemHeader) => {
      column = {
        title: itemHeader.value,
        data: [],
      };
      list.forEach((itemList) => {
        row = itemList[itemHeader.key];
        column.data.push(row);
      });
      sheet.push(column);
    });
  }
  key(object, key, states) {
    states.forEach((state) => {
      if (object[key] == state.key) {
        object[key] = state.value;
      }
    });
    return { ...object };
  }
  keys(
    object = {
      name: "ali",
      age: 10,
      status: "PENDING",
      gender: "M",
    },
    keys = [
      {
        key: "status",
        states: [
          { key: "PENDING", value: "درحال انتظار" },
          { key: "REJECTED", value: "رد شده" },
        ],
      },
      {
        key: "gender",
        states: [
          {
            key: "M",
            value: "مرد",
          },
          {
            key: "F",
            value: "زن",
          },
        ],
      },
    ]
  ) {
    keys.forEach((item) => {
      item.states.forEach((state) => {
        if (object[item.key] == state.key) {
          object[item.key] = state.value;
        }
      });
    });

    return { ...object };
  }
  list(
    list = [
      {
        name: "ali",
        age: 10,
        status: "PENDING",
        gender: "M",
      },
      {
        name: "mina",
        age: 10,
        status: "REJECTED",
        gender: "F",
      },
    ],
    keys = [
      {
        key: "status",
        states: [
          { key: "PENDING", value: "درحال انتظار" },
          { key: "REJECTED", value: "رد شده" },
        ],
      },
      {
        key: "gender",
        states: [
          {
            key: "M",
            value: "مرد",
          },
          {
            key: "F",
            value: "زن",
          },
        ],
      },
    ]
  ) {
    list.forEach((object) => {
      keys.forEach((item) => {
        item.states.forEach((state) => {
          if (object[item.key] == state.key) {
            object[item.key] = state.value;
          }
        });
      });
    });

    return [...list];
  }
  inline(value, translates, from, to) {
    let stValue;
    translates.forEach((item) => {
      if (item[from] == value) {
        stValue = item[to];
      }
    });
    return stValue;
  }


  // this method not used
  toPersianDate(
    date = new Date(),
    {
      showDate = true,
      showTime = true,
      partSeprator = "|",
      timeFormat = "HH:mm:ss",
      dateFormat = "YYYY/MM/DD",
    }
  ) {

    let stFormat;
    let stPartSeprator;
    let stTimeFormat;
    let stDateFormat;

    // seprator
    if (showTime == true && showDate == true) {
      stPartSeprator = partSeprator;
    } else {
      stPartSeprator = "";
    }

    // time
    if (showTime == true) {
      stTimeFormat = timeFormat;
    } else {
      stTimeFormat = "";
    }

    // date
    if (showDate == true) {
      stDateFormat = dateFormat;
    } else {
      stDateFormat = "";
    }
    stFormat = `${stDateFormat}${stPartSeprator}${stTimeFormat}`;
    return moment(date, stFormat).locale("fa").format(stFormat);
  }

// this method used in print-table component
  toShamsi(date) {

    let showDate = true;
    let showTime = true;
    let partSeprator = " ";
    let timeFormat = "HH:mm:ss";
    let dateFormat = "YYYY/MM/DD";

    let stFormat;
    let stPartSeprator;
    let stTimeFormat;
    let stDateFormat;

    // seprator
    if (showTime == true && showDate == true) {
      stPartSeprator = partSeprator;
    } else {
      stPartSeprator = "";
    }

    // time
    if (showTime == true) {
      stTimeFormat = timeFormat;
    } else {
      stTimeFormat = "";
    }

    // date
    if (showDate == true) {
      stDateFormat = dateFormat;
    } else {
      stDateFormat = "";
    }
    stFormat = `${stDateFormat}${stPartSeprator}${stTimeFormat}`;
    return moment(date, stFormat).locale("fa").format(stFormat);
  }

}

export default new Translate();
