<template>
  <span>
    <span v-show="!realNumber" :class="cssClass">
      <template v-if="animatedNumber > 1000">
        {{ parseInt(animatedNumber) | VMask(mask.noDecimal) }}
      </template>
      <template v-else-if="animatedNumber > 0.0001">
        {{ removeExponentialFormat(animatedNumber) | VMask(mask.fourDecimal) }}
      </template>
      <template v-else-if="Number.parseFloat(number) > 0.000001">
        {{ removeExponentialFormat(animatedNumber) | VMask(mask.sixDecimal) }}
      </template>
      <template v-else>
        {{ removeExponentialFormat(animatedNumber) | VMask(mask.eightDecimal) }}
      </template>
    </span>
    
    <span v-show="realNumber" :class="cssClass">
      <template v-if="Number.parseFloat(number) > 1000">
        {{ parseInt(number) | VMask(mask.noDecimal) }}
      </template>
      <template v-else-if="Number.parseFloat(number) > 0.0001">
        {{ removeExponentialFormat(number) | VMask(mask.fourDecimal) }}
      </template>
      <template v-else-if="Number.parseFloat(number) > 0.000001">
        {{ removeExponentialFormat(number) | VMask(mask.sixDecimal) }}
      </template>
      <template v-else>
        {{ removeExponentialFormat(number) }}
      </template>
    </span>
  </span>
</template>


<script>
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import { removeExponentialFormat } from "@/util/helpers";

const maskFour = createNumberMask({
  stopMasking: false,
  prefix: "",
  allowDecimal: true,
  decimalLimit: 4,
  integerLimit: null,
  requireDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowNegative: false,
});

const maskSix = createNumberMask({
  stopMasking: false,
  prefix: "",
  allowDecimal: true,
  decimalLimit: 6,
  integerLimit: null,
  requireDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowNegative: false,
});

const maskEight = createNumberMask({
  stopMasking: false,
  prefix: "",
  allowDecimal: true,
  decimalLimit: 8,
  integerLimit: null,
  requireDecimal: false,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowNegative: false,
});

const maskZero = createNumberMask({
  stopMasking: false,
  prefix: "",
  allowDecimal: false,
  integerLimit: null,
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ",",
  allowNegative: false,
});

export default {
  name: "animatedDollar",
  props: {
    number: {
      type: Number,
      default: 0,
    },
    animation: {
      type: Boolean,
      default: false,
    },
    increaseClass: {
      type: String,
      default: "",
    },
    decreaseClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      increase: false,
      decrease: false,
      realNumber: true,
      tweenedNumber: 0,
      mask: {
        eightDecimal: maskEight,
        sixDecimal: maskSix,
        fourDecimal: maskFour,
        noDecimal: maskZero,
      },
    };
  },
  computed: {
    animatedNumber() {
      return this.tweenedNumber.toFixed(8);
    },
    cssClass() {
      if(this.increase) {
        return this.increaseClass;
      }
      else if(this.decrease) {
        return this.decreaseClass;
      }
      else {
        return '';
      }
    }
  },
  watch: {
    number: function (newValue, oldValue) {
      if (newValue > oldValue) {
        this.increase = true;
      } else if (newValue < oldValue) {
        this.decrease = true;
      }

      setTimeout(() => {
        this.increase = false;
        this.decrease = false;
      }, 3000);

      if (this.animation == true) {
        gsap.to(this.$data, {
          duration: 2,
          tweenedNumber: newValue,
          onStart: () => {
            this.realNumber = false;
            setTimeout(() => {
              this.realNumber = true;
            }, 1000);
          },
        });
      }


    },
  },
  methods:{
    removeExponentialFormat
  }
};
</script>
