import store from "@/store";

const routes = [
  {
    path: "",
    component: () => import("@/layouts/empty/Index.vue"),
    children: [
      {
        path: "",
        redirect: "/login",
      },
      {
        path: "/register",
        name: "عضویت",
        component: () => import(/* webpackChunkName: "register" */ "@/views/global/authentication/registerLocalCaptcha.vue"),
        meta: {
          types: ["unauthorized"],
        },
      },
      {
        path: "/login",
        name: "ورود",
        component: () => import(/* webpackChunkName: "login" */ "@/views/global/authentication/loginLocalCaptcha.vue"),
        meta: {
          types: ["unauthorized"],
        },
      },
      {
        path: "/forget-password",
        name: "فراموشی رمز عبور",
        component: () =>
          import(/* webpackChunkName: "forget-password" */ "@/views/global/authentication/forgetPasswordLocalCaptcha.vue"),
        meta: {
          types: ["unauthorized"],
        },
      },
      {
        path: "/logout",
        name: "خروج",
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/accessRestrictions",
        component: () =>
          import(/* webpackChunkName: "accessRestrictions" */ "@/views/global/authentication/accessRestrictions.vue"),
        meta: {
          types: ["any"],
        },
      },
      {
        path: "/trading-view/:symbol/:theme/:range",
        name: "نمودار عرض",
        component: () => import(/* webpackChunkName: "tradingView" */ "@/views/global/tradingView.vue"),
        meta: {
          types: ["any"],
        },
      },
      {
        path: "/recently-toasts",
        name: "آخرین پیام ها",
        component: () => import(/* webpackChunkName: "tradingView" */ "@/views/global/recentlyToast.vue"),
        meta: {
          types: ["any"],
        },
      },
      {
        path: "/config/pwa/",
        name: "pwa",
        redirect: "/login",
      },
    ],
  },
];

export { routes };
