import store from "@/store";

const routes = [
  {
    path: "",
    component: () => import(/* webpackChunkName: "default-[request]" */ "@/layouts/panel/index.vue"),
    children: [
      {
        path: "/dashboard",
        name: "داشبورد",
        component: () => import(/* webpackChunkName: "dashboard" */ "@/views/customer/dashboard/dashboard.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/buyAndSell",
        name: "خرید و فروش",
        component: () => import(/* webpackChunkName: "buyAndSell" */ "@/views/customer/buyAndSell/buyAndSell.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/buyAndSell/:coinId/buy",
        name: "فرم ثبت خرید",
        component: () => import(/* webpackChunkName: "buyAndSell" */ "@/views/customer/buyAndSell/buy/buyForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/buyAndSell/:coinId/sell",
        name: "فرم ثبت فروش",
        component: () => import(/* webpackChunkName: "buyAndSell" */ "@/views/customer/buyAndSell/sell/sellForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet",
        name: "کیف پول",
        component: () => import(/* webpackChunkName: "wallet" */ "@/views/customer/wallet/wallet.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet-history",
        name: "سوابق",
        component: () => import(/* webpackChunkName: "wallet-history" */ "@/views/customer/wallet/history.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet-history/deposit/:depositId/details",
        name: "جزئیات واریز",
        component: () =>
          import(
            /* webpackChunkName: "wallet-history" */ "@/views/customer/wallet/components/deposit/depositDetails.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet-history/withdraw/:withdrawId/details",
        name: "جزئیات برداشت",
        component: () =>
          import(
            /* webpackChunkName: "wallet-history" */ "@/views/customer/wallet/components/withdraw/withdrawDetails.vue"
          ),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet/:coinSymbol/:coinId/deposit",
        name: "فرم ثبت واریز",
        component: () =>
          import(/* webpackChunkName: "deposit-form" */ "@/views/customer/wallet/deposit/depositForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet/:coinSymbol/:coinId/deposit/confirm/:depositId",
        name: "فرم ثبت اطلاعات واریز",
        component: () =>
          import(/* webpackChunkName: "deposit-form" */ "@/views/customer/wallet/deposit/depositConfirmForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/wallet/:coinSymbol/:coinId/withdraw",
        name: "فرم ثبت برداشت",
        component: () =>
          import(/* webpackChunkName: "withdraw-form" */ "@/views/customer/wallet/withdraw/withdrawForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/profile",
        name: "پروفایل",
        component: () => import(/* webpackChunkName: "profile" */ "@/views/customer/profile/profile.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/identity",
        name: "مدارک",
        component: () => import(/* webpackChunkName: "identity" */ "@/views/customer/identity/identity.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/level",
        name: "احراز هویت",
        component: () => import(/* webpackChunkName: "level" */ "@/views/customer/level/level.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/level/:level/:id/upgrade",
        name: "ارتقای احراز هویت",
        component: () => import(/* webpackChunkName: "upgrade" */ "@/views/customer/level/upgrade.vue"),
        meta: {
          types: ["authorized"],
        },
        beforeEnter: (to, from, next) => {
          if (store.getters.getMe.userInfo.level + 1 == to.params.level) {
            next();
          } else {
            next("/customer/level");
          }
        },
      },
      {
        path: "/bankAccount",
        name: "حساب های بانکی",
        component: () => import(/* webpackChunkName: "bankAccount" */ "@/views/customer/bankAccount/bankAccount.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/bankAccount/addForm",
        name: "افزودن حساب بانکی",
        component: () => import(/* webpackChunkName: "bankAccount" */ "@/views/customer/bankAccount/addForm.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/changePassword",
        name: "تنظیمات امنیتی",
        component: () => import(/* webpackChunkName: "change-password" */ "@/views/commons/changePassword.vue"),
        meta: {
          roles: ["customer", "admin", "employee"],
        },
      },
      {
        path: "/referralCode",
        name: "کسب درآمد",
        component: () =>
          import(/* webpackChunkName: "referral" */ "@/views/customer/referralCode/referralCode.vue"),
        meta: {
          types: ["authorized"],
        },
      },
      {
        path: "/referralCode/add",
        name: "ساخت کد معرفی",
        component: () =>
          import(/* webpackChunkName: "referral" */ "@/views/customer/referralCode/addReferralCode.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticket
      {
        path: "/ticket",
        name: "سوابق تیکت ها",
        component: () =>
          import(/* webpackChunkName: "ticket - list" */ "@/views/customer/tickets/ticketList.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticket/add
      {
        path: "/ticket/add",
        name: "ثبت تیکت جدید",
        component: () =>
          import(/* webpackChunkName: "ticket - add" */ "@/views/customer/tickets/addTicket.vue"),
        meta: {
          types: ["authorized"],
        },
      },

      // => /ticket/detail/:id
      {
        path: "/ticket/detail/:id",
        name: "شرح تیکت",
        component: () =>
          import(/* webpackChunkName: "ticket - detail" */ "@/views/customer/tickets/ticketDetail.vue"),
        meta: {
          types: ["authorized"],
        },
      },


      
    ],
  },
];

// ---------------- right side menu ----------------

const drawer = [
  {
    title: "داشبورد",
    icon: "mdi-view-dashboard",
    to: "/dashboard",
  },
  {
    title: "خرید و فروش",
    icon: "mdi-swap-horizontal",
    to: "/buyAndSell",
  },
  {
    title: "پشتیبانی تیکت",
    icon: "mdi-face-agent",
    to: `/ticket`,
  },
  {
    title: "کیف پول",
    icon: "mdi-wallet ",
    to: "/wallet",
  },
  {
    title: "سوابق",
    icon: "mdi-history ",
    to: "/wallet-history",
  },
  {
    title: "ارتقای احراز هویت",
    icon: "mdi-chevron-triple-up",
    to: "/level",
  },
  {
    title: "حساب های بانکی",
    icon: "mdi-bank",
    to: "/bankAccount",
  },
  {
    title: "کسب درآمد",
    icon: "mdi-currency-usd",
    to: "/referralCode",
  },
];

export { routes, drawer };
