import axios from "axios";
import store from "../store";

function getGlobalConfig(cb) {
  let api = `/production-config.json`;
  axios
    .get(api)
    .then((res) => {
      cb(res.data);
    })
    .catch((err) => {
      console.error('error on getting global config');
    });
}

function returnStoreConfig() {
  store.state.app.config = returnAppConfig();
}

function returnAppConfig() {
  return globalConfig;
}

export { getGlobalConfig, returnStoreConfig, returnAppConfig };
