export function leadingSlash(str) {
  return str.startsWith("/") ? str : "/" + str;
}

export function trailingSlash(str) {
  return str.endsWith("/") ? str : str + "/";
}

export const wait = (timeout) => {
  return new Promise((resolve) => setTimeout(resolve, timeout));
};


export function removeExponentialFormat(n) {
  
  var sign = +n < 0 ? "-" : "";
  if (!/e/i.test(n.toString())) {
    return n;
  }

  var [lead, decimal, pow] = n.toString()
    .replace(/^-/, "")
    .replace(/^([0-9]+)(e.*)/, "$1.$2")
    .split(/e|\./);

  var result = +pow < 0 ?
    sign + "0." + "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal :
    sign + lead + (+pow >= decimal.length ? (decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))) : (decimal.slice(0, +pow) + "." + decimal.slice(+pow)));

    console.log(` ${n} >>> converted to >>> ${result}`);
    return result;
}