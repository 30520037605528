<template>
  <v-slide-y-transition group tag="div" v-if="model == 'alert'">
    <v-alert
      text
      color="color4"
      type="info"
      dense
      dismissible
      prominent
      v-for="(text, index) in content"
      :key="`${index}-text`"
    >
      <template v-slot:close>
        <v-btn x-small color="color4 coloredBTN--text" rounded class="pa-0 ms-2" elevation="0" @click="removeItem()">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </template>
      <v-row align="center" justify="space-between">
        <v-col class="grow">
          <span class="text-caption color4--text">
            {{ text.message }}
          </span>
        </v-col>
        <v-col class="shrink">
          <v-btn
            color="color4 colorDeep1--text"
            rounded
            outlined
            class="text-caption"
            x-small
            :href="text.moreUrl"
            target="_blank"
            v-if="text.moreUrl != null && text.moreUrl != ''"
          >
            اطلاعات بیشتر
          </v-btn>
        </v-col>
        <v-col cols="12" class="pa-0 ma-0"> </v-col>
      </v-row>
    </v-alert>
  </v-slide-y-transition>
  <div v-else-if="model == 'text'">
    <v-card
      v-for="(text, index) in content"
      :key="`${index}-text`"
      color="transparent"
      elevation="0"
      class="ma-0 pa-0 my-2"
    >
      <v-card-text class="d-flex align-center ma-0 pa-0">
        <p class="text-caption colorDeep1--text ma-0 help-message">
          {{ text.message }}
          <span v-if="text.moreUrl != null && text.moreUrl != ''">
            <br/>
            <v-btn
              color="color4 colorDeep1--text"
              rounded
              outlined
              class="text-body-2 ms-4 my-2"
              small
              :href="text.moreUrl"
              target="_blank"
            >
              اطلاعات بیشتر
            </v-btn>
          </span>
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [],
      state: true,
    };
  },
  props: {
    apiKey: {
      type: String,
      default: null,
    },
    model: {
      type: String,
      default: "alert",
    },
  },
  methods: {
    getContent() {
      if (this.apiKey != null) {
        let api = `/customer/helpMessage/list/${this.apiKey}`;

        this.$http
          .get(api)
          .then((res) => {
            this.content = res.data;
          })
          .catch((err) => {
            this.$toast.onApi(err);
          });
      } else {
        this.content = [];
      }
    },
    removeItem(index) {
      this.content.splice(index, 1);
      if (this.content.length == 0) {
        this, $emit("empty");
      }
    },
  },
  mounted() {
    this.getContent();
  },
};
</script>

<style></style>
