import Vue from "vue";
import http from "../http/http";
import $router from "../router/index";

const globalMethods = {
  install(Vue, options) {
    Vue.prototype.$formatIRNumber = (number, options = {}) => {
      return new Intl.NumberFormat("ir", options).format(number);
    };
    Vue.prototype.$toEnDigits = (string) => {
      let entry = `${string}`;
      return entry
        .replace(/[\u0660-\u0669]/g, function (c) {
          return c.charCodeAt(0) - 0x0660;
        })
        .replace(/[\u06f0-\u06f9]/g, function (c) {
          return c.charCodeAt(0) - 0x06f0;
        });
    };

    Vue.prototype.$callerApi = (
      api,
      resolve = () => undefined,
      reject = () => undefined,
      finish = () => undefined
    ) => {
      api.loading = true;
      api.hasError = false;
      http
        .request(api.options)
        .then((res) => {
          api.res = res;
          resolve(api.res);
        })
        .catch((err) => {
          api.hasError = true;
          api.err = err;
          reject(api.err);
        })
        .finally(() => {
          setTimeout(() => {
            api.loading = false;
            finish();
          }, 50);
        });
    };

    Vue.prototype.$callerApiP = (api) => {
      return new Promise((resolve, reject) => {
        api.loading = true;
        api.hasError = false;
        http
          .request(api.options)
          .then((res) => {
            api.res = res;
            resolve(api.res);
          })
          .catch((err) => {
            api.err = err;
            reject(api.err);
          })
          .finally(() => {
            setTimeout(() => {
              api.loading = false;
            }, 50);
          });
      });
    };

    Vue.prototype.$isPageHasData = (items = [], page = 0) => {
      if (items.length == 0 && page > 1) {
        return true;
      } else {
        return false;
      }
    };

    Vue.prototype.$apiInstance = (api) => {
      return {
        ...api,
        loading: false,
        res: undefined,
        err: undefined,
        hasError: false,
      };
    };
    Vue.prototype.$apisInstance = (apis) => {
      for (const key in apis) {
        apis[key] = {
          ...apis[key],
          loading: false,
          res: undefined,
          err: undefined,
          hasError: false,
        };
      }
    };

    Vue.prototype.$fixDecimal = (value) => {
      let minusRegex = /[eE][-]/;
      let plusRegex = /[eE][+]/;
      let cleanedValue = value.toString().split(",").join("");
      let EXPNumber = Number(cleanedValue).toExponential();
      if (value > 1) {
        let intigerLength = EXPNumber.split(plusRegex)[0];
        let EXPLength = EXPNumber.split(plusRegex)[1];
        let EXPPow = Math.pow(10, EXPLength);
        let fullValue = EXPPow * intigerLength;
        return {
          expValue: EXPNumber,
          value: fullValue,
          fixedValue: fullValue,
        };
      } else if (value < 1) {
        let intigerLength = EXPNumber.split(minusRegex)[0];
        let EXPLength = EXPNumber.split(minusRegex)[1];
        let EXPPow = Math.pow(10, -1 * EXPLength);
        let fullValue = EXPPow * intigerLength;
        return {
          expValue: EXPNumber,
          value: fullValue,
          fixedValue: fullValue.toFixed(EXPLength),
        };
      }
    };

    Vue.prototype.$openDialog = (dialog, data) => {
      dialog.state = true;
      dialog.data = data;
    };
    Vue.prototype.$closeDialog = (dialog, data) => {
      dialog.state = false;
      setTimeout(() => {
        dialog.data = null;
      }, 500);
    };
    Vue.prototype.$closeDialogSync = (dialog, data) => {
      dialog.state = false;
      dialog.data = null;
    };

    Vue.prototype.$redirectTo = (path) => {
      $router.push({ path: path });
    };
  },
};

Vue.use(globalMethods);
