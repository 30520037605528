export function cutDecimals(number, decimalPlaces) {
  return  Math.trunc(number * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
}

export function commaSeperated(number) {
  let strNumber = number.toString();
  let integerPart = '';
  let decimalPart = '';
  
  if(strNumber.indexOf('.') > -1) {
    let parts = strNumber.split('.');
    integerPart = parts[0];
    decimalPart = parts[1];
  } else {
    integerPart = strNumber;
  }

  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart.length > 0 ? `${integerPart}.${decimalPart}` : integerPart;
}


export function formatAmount(amount, isToman, isDollar) {
  if (isDollar) {
    return  cutDecimals(amount, 2); 
  }
  else if(isToman || amount > 1000) { 
    return parseInt(amount); 
  } 
  else if (amount > 100) {
    return cutDecimals(amount, 4); 
  }
  else {
    return amount; 
  } 
} 


export function maskAmount(amount, isToman, isDollar) {
  if (isDollar) {
    return  commaSeperated(cutDecimals(amount, 2)); 
  }
  else if(isToman || amount > 1000) { 
    return commaSeperated(parseInt(amount)); 
  } 
  else if (amount > 100) {
    return commaSeperated(cutDecimals(amount, 4)); 
  }
  else {
    return commaSeperated(amount); 
  } 
} 