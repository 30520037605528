import vuetify from "@/plugins/vuetify";

export default {
  state: () => ({
    drawerState: true,
    drawerMini: false,
    drawerTemporary: false,
    dark: vuetify.framework.theme.dark,
    support: false,
    PWAState: true,
    PWAEvent: undefined,
    routeLoading: false,
    appConfig: undefined,
    barHeight: 50,
    uploadAcceptedFormats: ["png", "jpg", "jpeg"],
    uploadAcceptedMaxSize: 5120 // KB = 5MB
  }),
  mutations: {
    setDrawerState(state, boolean) {
      state.drawerState = boolean;
    },
    setDrawerMini(state, boolean) {
      state.drawerMini = boolean;
    },
    setDrawerTemporary(state, boolean) {
      state.drawerTemporary = boolean;
    },
    setDark(state, boolean) {
      state.dark = boolean;
    },
    setTheme(state) {
      let dark = localStorage.getItem("dark-mode");
      if (dark != null) {
        state.dark = JSON.parse(dark);
        vuetify.framework.theme.dark = JSON.parse(dark);
      } else {
        localStorage.setItem("dark-mode", JSON.stringify(state.dark));
      }
    },
    updateTheme(state, boolean) {
      vuetify.framework.theme.dark = boolean;
      state.dark = boolean;
      localStorage.setItem("dark-mode", JSON.stringify(boolean));
    },
    setSupport(state, boolean) {
      state.support = boolean;
    },
    setPWAState(state, boolean) {
      state.PWAState = boolean;
    },
    setPWAEvent(state, e) {
      state.PWAEvent = e;
    },
    setRouteLoading(state, boolean) {
      state.routeLoading = boolean;
    },
    setAppConfig(state) {
      state.appConfig = globalConfig;
    },
  },
  actions: {
    initial(context) {
      context.commit("setAppConfig");
      context.commit("setTheme");
    },
  },
  getters: {
    getDrawerState(state) {
      return state.drawerState;
    },
    getDrawerMini(state) {
      return state.drawerMini;
    },
    getDrawerTemporary(state) {
      return state.drawerTemporary;
    },
    getDark(state) {
      return state.dark;
    },
    getSupport(state) {
      return state.support;
    },
    getPWAState(state) {
      return state.PWAState;
    },
    getPWAEvent(state) {
      return state.PWAEvent;
    },
    getRouteLoading(state) {
      return state.routeLoading;
    },
    getAppConfig(state) {
      return state.appConfig;
    },
    getBarHeight(state) {
      return state.barHeight;
    },
    getUploadAcceptedFormats(state) {
      return state.uploadAcceptedFormats;
    },
  },
};
