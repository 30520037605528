import store from "@/store";
import Vue from "vue";

function onAddToast(object, cb) {
  store.commit("addToast", object);
  cb();
}

function onPushToPendingToast(cb) {
  store.commit("pushToPendingToast");
  cb();
}

function onPushToActiveToast() {
  if (store.getters.getActiveToastCount < store.getters.getActiveToastNumber) {
    store.commit("pushToActiveToast");
    setTimeout(() => {
      store.commit("shiftFromPendingToast");
      setTimeout(() => {
        store.commit("shiftFromActiveToast");
      }, store.getters.getTimer);
    }, 50);
  } else {
    setTimeout(() => {
      onPushToActiveToast();
    }, store.getters.getTimer + store.getters.getDelay);
  }
}

function updateRecentlyToast() {
  store.commit("setRecentlyToastFromLocalStorage");
}

class Toast {
  constructor() {}
  add(object) {
    onAddToast(object, () => {
      onPushToPendingToast(() => {
        onPushToActiveToast();
        setTimeout(() => {
          updateRecentlyToast();
        }, 50);
      });
    });
  }
  custom(object) {
    this.add(object);
  }

  success(object) {
    object = { ...object, color: "color6" };
    this.add(object);
  }

  error(object) {
    object = { ...object, color: "color3" };
    this.add(object);
  }

  ServerError(object) {
    object = {
      ...object,
      color: "color3 darken-2",
      dark: false,
      title: object?.title || "پاسخی از سرور دریافت نشد، اتصال اینترنت را بررسی نمایید",
    };
    this.add(object);
    console.error(`------------ Server Error occoured. status code: ${object.status} ------------`);
  }


  // Forbidden
  status403(object) {
    object = {
      ...object,
      color: "color3",
      dark: false,
      title: object?.title || "دسترسی به این عملیات ندارید، صفحه را رفرش نموده و مجددا لاگین نمایید",
    };
    this.add(object);
  }

  // Locked
  status423(object) {
    object = {
      ...object,
      color: "color3",
      dark: false,
      title: object?.title || "ماژول نصب نمی باشد",
    };
    this.add(object);
  }

  // Not Found
  status404(object) {
    object = {
      ...object,
      color: "color3",
      dark: false,
      title: object?.title || "api یافت نشد",
    };
    this.add(object);
  }

  // Unauthorized 
  status401(object) {
    object = {
      ...object,
      color: "color3",
      dark: false,
      title: object?.title || "زمان دسترسی شما به پنل به پایان رسیده است",
      messages: ["لطفا مجددا وارد حساب خود شوید"],
    };
    this.add(object);
  }

  info(object) {
    object = { ...object, color: "color4" };
    this.add(object);
  }

  help(object) {
    object = { ...object, color: "color4 darken-2", dark: false };
    this.add(object);
  }

  auto(object) {
    if (object.status == 404) {
      this.status404(object);
    }
    else if (object.status == 423) {
      this.status423(object);
    }
    else if (object.status == 403) {
      this.status403(object);
    }
    else if (object.status == 401) {
      this.status401(object);
    }
    else if (object.status >= 200 && object.status < 300) {
      this.success(object);
    }
    else if (object.status >= 400 && object.status < 500) {
      this.error(object);
    }
    // from 500 to 600
    else if ((object.status >= 500 && object.status < 600)) {
      this.ServerError(object);
    }
  }

  onApi(object) {
    let reMapedObject = {
      title: object?.response?.data?.msg || object?.response?.data?.code || object?.data?.msg || object.title,
      messages: object?.response?.data?.errors || object?.data?.errors || object.messages,
      status: object?.response?.status || object?.status || 0,
    };
    
    this.auto(reMapedObject);
  }
}

const appToast = new Toast();

const toast = {
  install(Vue, options) {
    Vue.prototype.$toast = appToast;
  },
};

Vue.use(toast);

export { appToast };
