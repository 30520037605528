import { formatAmount, maskAmount, commaSeperated } from '../amountFormatter'

export function tomanFormated(value) {
  return formatAmount(value, true, false);
}

export function tomanMasked(value) {
  return maskAmount(value, true, false);
}

export function dollarFormated(value) {
  return formatAmount(value, false, true);
}

export function dollarMasked(value) {
  return maskAmount(value, false, true);
}

export function cryptoFormated(value) {
  return formatAmount(value, false, false);
}

export function cryptoMasked(value) {
  return maskAmount(value, false, false);
}

export function commaSeperate(value) {
  return commaSeperated(value);
}