<template>
  <div>
    <v-btn depressed rounded color="primary" class="mr-2" @click="print">{{this.btnText}}</v-btn>
    
    <div ref="header" v-show="false">
      <div class="print-header">
        <img class="print-logo" :src="$store.state.app.config.logo.light"/>
        <h2 class="print-title">{{$store.state.app.config.title}}</h2>
        <hr/>
        <h4 class="page-title">{{printText}}</h4>
      </div>
    </div>

    <div ref="content">
      <div class="print-content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      windowOptions: 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes',
    }
  },
  props:{
     btnText : {
      type: String,
      default: 'پرینت'
     },
     printText : {
      type: String,
      default: ''
     }
  },
  methods: {
    print() {
      var content = this.$refs.header.innerHTML + '<div class="print-content">'+ this.removeButtons(this.$refs.content.innerHTML) +'</div>';

      var win = window.open("", "_blank", this.windowOptions);
      win.document.write(`<html><head><title>پرینت</title><link rel="stylesheet" type="text/css" href="${this.$store.state.app.config.printPageCssFile}"></head><body>`);
      win.document.write(content);
      win.document.write('</body></html>');
      setTimeout(()=>{ win.print(); }, 100);  // let page to load css styles
    },
    removeButtons(content){
      var div = document.createElement('div');
      div.innerHTML = content;

      var buttons = div.getElementsByTagName('button');
      
      while (buttons[0]) {
        buttons[0].parentNode.removeChild(buttons[0]);
      }

      return div.innerHTML;
    }
  }

};
</script>