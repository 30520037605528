import router from "@/router";

import Vue from "vue";
import Vuex from "vuex";

import applicationStore from "./modules/app";
import userStore from "./modules/user";
import userNewStore from "./modules/userNew";
import settingStore from "./modules/setting";
import toastStore from "./modules/toast";

Vue.config.devtools = true;

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {
    saveUserDetailsToLocalStorage(state) {
      let userDetail = localStorage.getItem("userDetails");
      if (userDetail != null) {
        state.user.info = JSON.parse(userDetail);
      } else {
        localStorage.setItem("userDetails", JSON.stringify(state.user.info));
      }

      let authToken = localStorage.getItem("ramzino-auth-token");
      localStorage.setItem("ramzino-auth-token", state.user.authToken);
      if (authToken != null || authToken != "null") {
        state.user.info = authToken;
      } else {
        localStorage.setItem("ramzino-auth-token", null);
      }
    },
  },
  actions: {
    initial(context) {
      
    },
  },
  modules: {
    app: applicationStore,
    user: userStore,
    userNew: userNewStore,
    setting: settingStore,
    toast: toastStore,
  },
});
