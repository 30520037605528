import IMask from "imask";

const numberMask = (number) => {
    if (typeof number == "number") {
      let options = {
        mask: Number, // enable number mask
        // other options are optional with defaults below
        scale: 8, // digits after point, 0 for integers
        signed: true, // disallow negative
        thousandsSeparator: ",", // any single char
        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: ".", // fractional delimiter
        mapToRadix: ["."], // symbols to process as radix
      };
      let noMaskedOptions = {
        mask: Number, // enable number mask
        // other options are optional with defaults below
        scale: 8, // digits after point, 0 for integers
        signed: true, // disallow negative
        thousandsSeparator: "", // any single char
        padFractionalZeros: false, // if true, then pads zeros at end to the length of scale
        normalizeZeros: true, // appends or removes zeros at ends
        radix: ".", // fractional delimiter
        mapToRadix: ["."], // symbols to process as radix
      };
      let fixedNumber = number.toFixed(8);
      let maskedNumber = IMask.pipe(fixedNumber.toString(), options);
      let unmaskedNumber = IMask.pipe(fixedNumber.toString(), noMaskedOptions);
      return {
        entry: number,
        fixed: fixedNumber,
        masked: maskedNumber,
        unmasked: unmaskedNumber,
      };
    } else {
      return {
        entry: "your entry is not a number",
        fixed: "your entry is not a number",
        masked: "your entry is not a number",
        unmasked: "your entry is not a number",
      };
    }
  };

  export default numberMask;