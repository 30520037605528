import Vue from "vue";

// import apex chart
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);

// import vue elipse progress
import VueEllipseProgress from "vue-ellipse-progress";
Vue.use(VueEllipseProgress);

// import page transition
import VuePageTransition from "vue-page-transition";
Vue.use(VuePageTransition);

// vue clipboard
import VueClipboards from "vue-clipboards";
Vue.use(VueClipboards);

// vue count down
import vueAwesomeCountdown from "vue-awesome-countdown";
Vue.use(vueAwesomeCountdown, "vac"); // Component name, `countdown` and `vac` by default

// vue v-mask
import VueMask from "v-mask";
Vue.use(VueMask);
// Or as a directive-only
import { VueMaskDirective } from "v-mask";
Vue.directive("mask", VueMaskDirective);
// Or only as a filter-only
import { VueMaskFilter } from "v-mask";
Vue.filter("VMask", VueMaskFilter);


// my custom filters
import { tomanFormated, tomanMasked, dollarFormated, dollarMasked, cryptoFormated, cryptoMasked, commaSeperate } from './filters/amountFormatterFilter'
Vue.filter("tFormat", tomanFormated);
Vue.filter("tMask", tomanMasked);
Vue.filter("dFormat", dollarFormated);
Vue.filter("dMask", dollarMasked);
Vue.filter("cFormat", cryptoFormated);
Vue.filter("cMask", cryptoMasked);
Vue.filter("commaSeperate", commaSeperate);


// vue-persian-datetime-picker
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
Vue.component("date-picker", VuePersianDatetimePicker);

// vue2-filters
import Vue2Filters from "vue2-filters";
Vue.use(Vue2Filters);

// key modifiers
Vue.config.keyCodes.save = 83;

// persian filters
import vuePersianFilters from "vue-persian-filters";
Vue.use(vuePersianFilters);

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

