import Vue from "vue";
import IMask from "imask";
import store from "../store/index";
const masks = {
  install(Vue, options) {
    Vue.prototype.$checkPermision = (api, method) => {
      if (store.state.user.mainRole == "employee") {
        let routes = store.state.user.user.routes;
        let mapedRoutes = routes.map((route) => {
          return {
            method: route.split("|")[0],
            path: route.split("|")[1],
          };
        });
        let result = mapedRoutes.find((route) => {
          return route.method == method && route.path == api;
        });
        if (result) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    };
  },
};

Vue.use(masks);
