import Vue from "vue";
import preOrderHub from "../sockets/preOrderHub";

const preOrderHubPlugin = {
  install(Vue, options) {
    Vue.prototype.$PREORDERHUB = preOrderHub;
  },
};

Vue.use(preOrderHubPlugin);
