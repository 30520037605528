import vuetify from "@/plugins/vuetify";
import { returnAppConfig } from "@/util/getConfigs";

export default {
  state: () => ({
    info: {
      authorized: false,
      role: "guest",
    },
    guard: {
      routes: [],
    },
    drawerState: true,
    drawerMini: false,
    drawerTemporary: false,
    // appCDNUrl: process.env.VUE_APP_CDN_URL,
    appCDNUrl: returnAppConfig().cdn,
    displayLastOrders : returnAppConfig().displayLastOrdersOnCustomerDashboard,
    dark: vuetify.framework.theme.dark,
    authToken: null,
    mainRole: null,
    user: null,
    drawerData: null,
    userRouter: null,
    logStatus: null,
    logText: null,
    logErrors: [],
    logBox: false,
    tradingView: null,
    tradingViewConfig: {
      autosize: true,
      theme: "dark",
      symbol: "NASDAQ:AAPL",
      interval: "D",
      timezone: "Etc/UTC",
      style: "1",
      locale: "en",
      toolbar_bg: "#f1f3f6",
      enable_publishing: false,
      hide_top_toolbar: true,
      hide_legend: true,
      container_id: "tradingview_1",
    },
  }),
  mutations: {
    updateState(state, object) {
      for (const key in object) {
        state[key] = object[key];
      }
    },
  },
  actions: {},
  getters: {},
};
