import Vue from "vue";
import stableCoinP2pOrderHub from "../sockets/stableCoinP2pOrderHub";

const stableCoinP2pOrderHubPlugin = {
  install(Vue, options) {
    Vue.prototype.$STABLECOINP2PORDERHUB = stableCoinP2pOrderHub;
  },
};

Vue.use(stableCoinP2pOrderHubPlugin);
