<template>
  <v-dialog
    v-model="state"
    @click:outside="close()"
    v-bind="{ fullscreen: $attrs.fullscreen, width: $attrs.width || 550 }"
    v-if="data != null"
    transition="dialog-bottom-transition"
  >
    <v-card v-bind="{ color: $attrs.color }">
      <v-card-title>
        <slot name="title" v-bind="{ title, data }">
          <h6 class="text-body-2">{{ title }}</h6>
        </slot>
        <v-spacer></v-spacer>
        <slot name="afterTitle"></slot>
        <v-btn v-if="closeOnTop == true" icon color="color3" @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="dialog-text">
        <slot name="content" v-bind="{ data }"> dialog content </slot>
      </v-card-text>
      <slot v-if="append == true">
        <v-divider></v-divider>
        <v-card-actions>
          <slot name="action" v-bind="{ data }"> </slot>
          <v-spacer></v-spacer>
          <btn-cancel v-if="closeOnBottom == true" @after:click="close()"></btn-cancel>
        </v-card-actions>
      </slot>
    </v-card>
  </v-dialog>
  <!-- example -->
  <!-- 
    <km-dialog
      :dialog="dialogs.test"
      @closed="$closeDialog(dialogs.test)"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <template v-slot:content="{ data }">
        {{ data }}
      </template>
    </km-dialog>
   -->
</template>

<script>
export default {
  data() {
    return {
      state: false,
      data: null,
    };
  },
  props: {
    dialog: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "دیالوگ",
    },
    append: {
      type: Boolean,
      default: true,
    },
    closeBtn: {
      type: [Boolean, String],
      default: "top",
    },
    closeOnTop: {
      type: [Boolean],
      default: true,
    },
    closeOnBottom: {
      type: [Boolean],
      default: true,
    },
  },
  methods: {
    close() {
      this.state = false;
      this.$emit("closed");
      setTimeout(() => {
        if (this.state == false) {
          this.data = null;
        }
      }, 150);
    },
    open() {
      this.state = true;
      this.data = this.dialog.data;
      this.$emit("opened");
    },
  },
  watch: {
    dialog: {
      handler(dg) {
        if (dg.state == false) {
          this.close();
        } else {
          this.open();
        }
      },
      deep: true,
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.dialog.state == false) {
        this.close();
      } else {
        this.open();
      }
    }, 50);
  },
};
</script>

<style></style>
