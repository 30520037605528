<template>
  <v-btn
    min-width="100"
    rounded
    :color="`${color} ${loading == true ? 'darken-3' : ''} coloredBTN--text`"
    class="text-body-2 font--medium"
    :loading="loading"
    :outlined="loading"
    :disabled="disabled"
    :elevation="loading == true ? 0 : 4"
    @click="call()"
  >
    <template v-if="hideIcon == true">
      <div class="km-btn-content d-flex justify-center">
        <span class="me-2">{{ text }}</span>
      </div>
    </template>
    <template v-if="hideIcon == false">
      <div class="km-btn-content d-flex justify-space-between">
        <span class="me-2">{{ text }}</span>
        <v-icon small>{{ icon }}</v-icon>
      </div>
    </template>

    <template v-slot:loader>
      <div class="km-btn-content px-4 d-flex justify-space-between">
        <span class="me-2">{{ text }}</span>
        <v-progress-circular size="16" width="2" indeterminate :color="`${color} darken-3`"></v-progress-circular>
      </div>
    </template>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "ثبت",
    },
    icon: {
      type: String,
      default: "mdi-check",
    },

    color: {
      type: String,
      default: "color6",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    hideIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    call() {
      this.$emit("after:click");
    },
  },
};
</script>

<style>
.km-btn-content {
  width: 100%;
}
</style>
