var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-snackbar',{attrs:{"color":_vm.$store.state.user.logStatus == 200
        ? 'color6 lighten-5'
        : _vm.$store.state.user.logStatus == 400
        ? 'color3 lighten-5'
        : 'color2 lighten-5',"left":_vm.$vuetify.breakpoint.smAndUp,"bottom":"","rounded":"","elevation":"4","timeout":"20000"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({class:_vm.$store.state.user.logStatus == 200
            ? 'color6 coloredBTN--text'
            : _vm.$store.state.user.logStatus == 400
            ? 'color3 coloredBTN--text'
            : 'color1 coloredBTN--text',attrs:{"top":_vm.$store.state.user.logErrors.length > 0 ? true : false,"absolute":_vm.$store.state.user.logErrors.length > 0 ? true : false,"left":"","color":_vm.$store.state.user.logStatus == 200 ? 'color6' : _vm.$store.state.user.logStatus == 400 ? 'color3' : 'color1',"icon":"","x-small":"","rounded":""},on:{"click":function($event){_vm.$store.state.user.logBox = false}}},'v-btn',attrs,false),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-close")])],1)]}}]),model:{value:(_vm.$store.state.user.logBox),callback:function ($$v) {_vm.$set(_vm.$store.state.user, "logBox", $$v)},expression:"$store.state.user.logBox"}},[_c('span',{staticClass:"font--medium text-body-2",class:_vm.$store.state.user.logStatus == 200
          ? 'color6--text'
          : _vm.$store.state.user.logStatus == 400
          ? 'color3--text'
          : 'color2--text'},[_vm._v(" "+_vm._s(_vm.$store.state.user.logText)+" ")]),_c('div',{staticClass:"log-snackbar"},_vm._l((_vm.$store.state.user.logErrors),function(error,index){return _c('v-alert',{key:index,staticClass:"my-2 py-2 justify-start text-caption",attrs:{"color":_vm.$store.state.user.logStatus == 200 ? 'color6 ' : _vm.$store.state.user.logStatus == 400 ? 'color3 ' : 'color2 ',"text":"","rounded":"","ripple":false,"block":""}},[_vm._v(_vm._s(error))])}),1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }