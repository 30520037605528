<template>
  <v-btn small icon @click="openLink()" color="color6">
    <v-icon>mdi-open-in-new</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    link: {
      default: null,
      type: String,
    },
  },
  methods: {
    openLink() {
      window.open(this.link);
    },
  },
};
</script>

<style></style>
