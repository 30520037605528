<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
export default {
  name: "App",
  metaInfo() {
    return {
      title: `${
        this.$store.state.app.config != null
          ? this.$store.state.app.config.title
          : ""
      }`,
      titleTemplate: this.$route.name ? `%s | ${this.$route.name}` : "",
      htmlAttrs: { lang: "en" },
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "description", content: this.$store.state.app.config != null ? this.$store.state.app.config.title : "صرافی ارز دیجیتال" },
      ],
    };
  },
  methods: {
    getApiVersion() {
      let api = `/version`;
      this.$http
        .get(api)
        .then((res) => {
          this.$store.state.app.version.api = res.data.version;
          this.$store.state.app.version.ui = require("../package.json").version;
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.getApiVersion();
  },
};
</script>
<style scoped>
</style>


