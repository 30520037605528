import store from "@/store";
export default {
  state: () => ({
    pendingToasts: [],
    activeToastNumber: 10,
    timer: 6000,
    delay: 500,
    activeToast: [],
    maxSaveToast: 60,
    recentlyToast: [],
    recentlyToastCount: 0,
    newToast: undefined,
  }),
  mutations: {
    pushToPendingToast(state) {
      state.pendingToasts.push({ ...state.newToast, state: "pending" });
      state.newToast = undefined;
    },
    pushToActiveToast(state) {
      if (state.pendingToasts.length > 0) {
        state.activeToast.push({
          ...state.pendingToasts[0],
          state: "active",
        });
      }
    },
    removeItemFromActive(state, id) {
      let itemIndex = state.activeToast.findIndex((item) => {
        return item.id == id;
      });
      state.activeToast.splice(itemIndex, 1);
    },
    shiftFromPendingToast(state) {
      if (state.pendingToasts.length > 0) {
        state.pendingToasts.shift();
      }
    },
    shiftFromActiveToast(state) {
      if (state.activeToast.length > 0) {
        state.activeToast.shift();
      }
    },

    addToast(state, toast) {
      let reMapedToast = {
        ...toast,
        id: Math.floor(Math.random() * 10000),
        date: new Date(),
        mobile: store.getters.getMe?.userInfo?.mobile || "guest",
      };
      let lsToasts = localStorage.getItem("toasts");
      let parsedToasts = JSON.parse(lsToasts);
      if (lsToasts) {
        if (parsedToasts.length >= state.maxSaveToast) {
          parsedToasts.shift();
        }
        if (toast.save == undefined || toast.save == true) {
          parsedToasts.push(reMapedToast);
          state.recentlyToastCount = parsedToasts.length;
          localStorage.setItem("toasts", JSON.stringify(parsedToasts));
        }
      } else {
        if (toast.save == undefined || toast.save == true) {
          let storageData = [];
          storageData.push(reMapedToast);
          state.recentlyToastCount = parsedToasts.length;
          localStorage.setItem("toasts", JSON.stringify(storageData));
        }
      }
      state.newToast = { ...reMapedToast };
    },

    setRecentlyToastCountFromLocalStorage(state) {
      let lsToasts = localStorage.getItem("toasts");
      if (lsToasts) {
        state.recentlyToastCount = JSON.parse(lsToasts).length;
      } else {
        state.recentlyToastCount = 0;
      }
    },
    setRecentlyToastFromLocalStorage(state) {
      let getLocalStorageToasts = localStorage.getItem("toasts");
      if (getLocalStorageToasts) {
        try {
          JSON.parse(localStorage.getItem("toasts"));
        } catch (error) {
          localStorage.setItem("toasts", "[]");
        }

        let parsedLocalStorageToast = JSON.parse(localStorage.getItem("toasts"));
        if (Array.isArray(parsedLocalStorageToast) == false) {
          localStorage.setItem("toasts", "[]");
        }

        let lsToasts = localStorage.getItem("toasts");
        state.recentlyToast = JSON.parse(lsToasts);
        state.recentlyToastCount = JSON.parse(lsToasts).length;
      } else {
        localStorage.setItem("toasts", "[]");
        state.recentlyToastCount = 0;
      }
    },
    removeSelectedToastFromLocalStorage(state, id) {
      let lsToasts = localStorage.getItem("toasts");
      if (lsToasts) {
        let parsedToasts = JSON.parse(lsToasts);
        let findedIndex = parsedToasts.findIndex((item) => {
          return item.id == id;
        });
        if (findedIndex != -1) {
          parsedToasts.splice(findedIndex, 1);
        }
        localStorage.setItem("toasts", JSON.stringify(parsedToasts));
      }
    },

    removeAllFilteredRecentlyToastFromLocalStorage(state) {
      let filteredItems;
      if (store.getters.getMe.userInfo) {
        filteredItems = state.recentlyToast.filter((item) => {
          return item.mobile == store.getters.getMe.userInfo.mobile;
        });
      } else {
        filteredItems = state.recentlyToast.filter((item) => {
          return item.mobile == "guest";
        });
      }
      filteredItems.forEach((item) => {
        store.commit("removeSelectedToastFromLocalStorage", item.id);
      });
    },

    removeAllRecentlyToastFromLocalStorage(state) {
      state.recentlyToast = [];
      state.recentlyToastCount = 0;
      localStorage.setItem("toasts", "[]");
    },
  },
  actions: {
    initial(context) {
      context.commit("setRecentlyToastFromLocalStorage");
      context.commit("setRecentlyToastCountFromLocalStorage");
    },
  },
  getters: {
    getNewToast(state) {
      return state.newToast;
    },
    getPendingToast(state) {
      return state.pendingToasts;
    },
    getPendingToastCount(state) {
      return state.pendingToasts.length;
    },
    getActiveToast(state) {
      return state.activeToast;
    },
    getActiveToastCount(state) {
      return state.activeToast.length;
    },
    getTimer(state) {
      return state.timer;
    },
    getDelay(state) {
      return state.delay;
    },
    getActiveToastNumber(state) {
      return state.activeToastNumber;
    },
    getRecentlyToastCount(state) {
      return state.recentlyToastCount;
    },
    getRecentlyToast(state) {
      return state.recentlyToast;
    },
    getRecentlyToastReverse(state) {
      return state.recentlyToast.reverse();
    },

    getRecentlyToastCountFiltered(state) {
      if (store.getters.getMe.userInfo) {
        let filteredItems = state.recentlyToast.filter((item) => {
          return item.mobile == store.getters.getMe.userInfo.mobile;
        });
        return filteredItems.length;
      } else {
        let filteredItems = state.recentlyToast.filter((item) => {
          return item.mobile == "guest";
        });
        return filteredItems.length;
      }
    },
  },
};
