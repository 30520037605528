<template>
  <v-row justify="space-between" class="mt-2">
    <v-col cols="auto">
      <v-pagination
        class="my-2"
        color="color4"
        v-model="page"
        :length="totalPages"
        :total-visible="totalVisible"
        @input="changeEvent(uuid)"
      ></v-pagination>
    </v-col>
    <v-col cols="auto">
      <v-select
        v-model="pageSize"
        rounded
        outlined
        dense
        :items="selectItems"
        color="color4"
        hide-details
        item-color="color4"
        @input="changeEvent(uuid)"
      >
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "kmPagination",
  data() {
    return {
      page: 1,
      pageSize: 10,
      totalPages: 10,
      totalVisible: 4,
      hasMore: false,
      selectItems: [10, 20, 50],
      setData: {
        page: 10,
        pageSize: 20,
        totalPages: 20,
        totalVisible: 8,
        hasMore: false,
        selectItems: [10, 20, 50, 80],
      },
    };
  },
  props: {
    uuid: {
      type: [String, Number],
      default: null,
    },
  },
  methods: {
    changeEvent(key) {
      if (this.uuid != null) {
        this.$emit(`pagination:${key}`);
      } else {
        this.$emit(`pagination`);
      }
    },
    getPaginationData() {
      return {
        page: this.page,
        pageSize: this.pageSize,
        totalPages: this.totalPages,
        totalVisible: this.totalVisible,
        hasMore: this.hasMore,
        selectItems: this.selectItems,
      };
    },
    setPaginationData(data) {
      this.page = data.page;
      this.pageSize = data.pageSize;
      this.totalPages = data.totalPages;
      this.hasMore = data.hasMore;
    },
  },
};
</script>

<style></style>
