import store from "@/store";

const hubUrl = `${returnAppConfig().api}/PreOrderHub`;

import { HubConnectionBuilder, LogLevel, HttpTransportType } from "@microsoft/signalr";
import { IS_PROD } from "../util/globals";
import { returnAppConfig } from "../util/getConfigs";

// signalR:options
const options = {
  // skipNegotiation: true,
  // transport: HttpTransportType.WebSockets,
  accessTokenFactory: () => store.getters.getUserInfo.token,
};

// =====================================
// =====================================
// =====================================

class PREORDERHUB {
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(hubUrl, options)
      .configureLogging(IS_PROD == true ? LogLevel.Trace : LogLevel.Information)
      .withAutomaticReconnect([0, 0, 10000])
      .build();
  }

  start() {
    this.connection
      .start()
      .then(() => {
         //console.log("PreOrder Socket Connected.");
      })
      .catch((error) => {
        //console.error("error - PreOrder Socket", error);
        setTimeout(async () => {
          await this.start();
        }, 7000);
      });
  }

  restart() {
    this.connection
      .start()
      .then(() => {
        //console.log("--> PreOrder Socket reConnected.");
      })
      .catch((error) => {
        //console.error("--> error on restart PreOrder Socket", error);
        setTimeout(async () => {
          await this.restart();
        }, 7000);
      });
  }

  try() {
    this.connection.onclose(async () => {
      await this.restart();
       //console.log("--> PreOrder Socket is onclose");
    });

    this.connection.onreconnecting(async () => {
      //console.log("--> PreOrder Socket is onreconnecting");
    });
    
    this.connection.onreconnected(async () => {
      //console.log("--> PreOrder Socket is onreconnected");
    });
  }

  run() {
    this.start();
    this.try();
  }

  async listen(event, getData) {
    this.connection.on(event, (data) => {
      getData(data);
    });
  }
}

export default new PREORDERHUB();
