import http from "@/http/http";

class Service {
  constructor(http) {
    this.http = http;
    this.apis = {
      me: {
        options: {
          method: "get",
          url: "/auth/me",
        },
      },
    };
    this.me = {
      authorized: false,
    };
    this.accessType = ["unauthorized"];
    this.role = "guest";
    this.routes = [];
  }
  callerApi = (api) => {
    if (this.getApiAccess(api)) {
      return new Promise((resolve, reject) => {
        api.loading = true;
        api.hasError = false;
        api.isCalled = true;
        this.http
          .request(api.options)
          .then((res) => {
            api.res = res;
            resolve(api.res);
          })
          .catch((err) => {
            api.err = err;
            api.hasError = true;
            reject(api.err);
          })
          .finally(() => {
            setTimeout(() => {
              api.loading = false;
            }, 50);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        resolve();
        reject();
      });
    }
  };
  apisInstance = (apis) => {
    for (const key in apis) {
      apis[key] = {
        ...apis[key],
        loading: false,
        res: undefined,
        err: undefined,
        hasError: false,
        isCalled: false,
      };
    }
  };
  apiInstance = (api) => {
    return {
      ...api,
      loading: false,
      res: undefined,
      err: undefined,
      hasError: false,
      isCalled: false,
    };
  };
  setRole() {
    if (this.me.authorized == true) {
      if (this.me.roles.includes("admin")) {
        return (this.role = "admin");
      } else if (this.me.roles.includes("customer")) {
        return (this.role = "customer");
      } else {
        return (this.role = "employee");
      }
    } else return (this.role = "guest");
  }
  setRoutes() {
    if (this.me.authorized == true) {
      let routes = this.me.routes.map((route, index) => {
        return {
          method: route.split("|")[0],
          url: route.split("|")[1],
          index: index,
        };
      });
      return (this.routes = [...this.routes, ...routes]);
    } else this.routes = [];
  }
  getRoutes() {
    return this.routes;
  }
  getMe() {
    return new Promise((resolve, reject) => {
      this.callerApi(this.apis.me)
        .then((res) => {
          this.me = {
            authorized: true,
            ...res.data,
          };
          this.setRole();
          this.setRoutes();
          this.setAccessType();
          resolve({
            data: {
              me: this.me,
              routes: this.routes,
              accessType: this.accessType,
              role: this.role,
            },
          });
        })
        .catch((err) => {
          this.me = {
            test: true,
            authorized: false,
            err: { ...err },
          };
          this.setRole();
          this.setRoutes();
          this.setAccessType();
          reject(err);
        });
    });
  }
  getApiAccess(api) {
    if (this.role == "employee") {
      let findedRoute = this.routes.find((route) => {
        return route.method == api.options.method && route.url == api.options.url;
      });

      if (findedRoute) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }

  setAccessType() {
    if (this.role == "employee" || this.role == "admin" || this.role == "customer") {
      this.accessType = ["authorized", "global", this.role];
    } else this.accessType = ["unauthorized"];

  }
}

export default new Service(http);
