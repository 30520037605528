<template>
  <!-- this component is depricated and ou shoud use 'this.$toast' , $toast dependensies ==> paths:'@/components/toast' , '@/plugins/toast' , '@/store/modules/toast' -->
  <div class="text-center">
    <v-snackbar
      :color="
        $store.state.user.logStatus == 200
          ? 'color6 lighten-5'
          : $store.state.user.logStatus == 400
          ? 'color3 lighten-5'
          : 'color2 lighten-5'
      "
      v-model="$store.state.user.logBox"
      :left="$vuetify.breakpoint.smAndUp"
      bottom
      rounded
      elevation="4"
      timeout="20000"
    >
      <span
        class="font--medium text-body-2"
        :class="
          $store.state.user.logStatus == 200
            ? 'color6--text'
            : $store.state.user.logStatus == 400
            ? 'color3--text'
            : 'color2--text'
        "
      >
        {{ $store.state.user.logText }}
      </span>
      <div class="log-snackbar">
        <v-alert
          :color="
            $store.state.user.logStatus == 200 ? 'color6 ' : $store.state.user.logStatus == 400 ? 'color3 ' : 'color2 '
          "
          text
          rounded
          :ripple="false"
          class="my-2 py-2 justify-start text-caption"
          v-for="(error, index) in $store.state.user.logErrors"
          :key="index"
          block
          >{{ error }}</v-alert
        >
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn
          class=""
          :top="$store.state.user.logErrors.length > 0 ? true : false"
          :absolute="$store.state.user.logErrors.length > 0 ? true : false"
          left
          :color="
            $store.state.user.logStatus == 200 ? 'color6' : $store.state.user.logStatus == 400 ? 'color3' : 'color1'
          "
          :class="
            $store.state.user.logStatus == 200
              ? 'color6 coloredBTN--text'
              : $store.state.user.logStatus == 400
              ? 'color3 coloredBTN--text'
              : 'color1 coloredBTN--text'
          "
          icon
          x-small
          rounded
          v-bind="attrs"
          @click="$store.state.user.logBox = false"
        >
          <v-icon small> mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "VLog",
};
</script>

<style scoped>
.log-snackbar {
  max-height: 50vh;
  overflow-y: auto;
}
</style>
