<template>
  <span class="d-flex justify-end persian-date" style="direction: ltr" v-if="date != null">
    <template v-if="easyFormat == true">
      {{ value | toPersianDate(format) }}
    </template>
    <template v-else>
      <template v-if="justDate">
        <template>
          {{ value | toPersianDate(date) }}
        </template>
      </template>
      <template v-else-if="justTime">
        <template>
          {{ value | toPersianDate(time) }}
        </template>
      </template>
      <template v-else-if="justRelativeDate">
        <template>
          {{ value | toRelativeDate() }}
        </template>
      </template>

      <template v-else>
        <template v-if="showDate">
          {{ value | toPersianDate(date) }}
          <v-divider v-if="showTime || showRelativeDate" vertical class="mx-2"></v-divider>
        </template>
        <template v-if="showTime">
          {{ value | toPersianDate(time) }}
          <v-divider v-if="showRelativeDate" vertical class="mx-2"></v-divider>
        </template>
        <template v-if="showRelativeDate">
          {{ value | toRelativeDate() }}
        </template>
      </template>
    </template>
  </span>
</template>

<script>

export default {
  data() {
    return {
      test: new Date(),
      date: undefined,
    };
  },
  props: {
    value: {
      type: String,
      default: new Date(),
    },
    easyFormat: {
      type: Boolean,
      default: false,
    },
    format: {
      type: String,
      default: "YYYY/MM/DD/ | HH:mm:ss",
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    justDate: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
    justTime: {
      type: Boolean,
      default: false,
    },
    showRelativeDate: {
      type: Boolean,
      default: false,
    },
    justRelativeDate: {
      type: Boolean,
      default: false,
    },
    timeSeparator: {
      type: String,
      default: ":",
    },
    dateSeparator: {
      type: String,
      default: "/",
    },
    year: {
      type: String,
      default: "YYYY",
    },
    month: {
      type: String,
      default: "MM",
    },
    day: {
      type: String,
      default: "DD",
    },
    hour: {
      type: String,
      default: "HH",
    },
    minute: {
      type: String,
      default: "mm",
    },
    second: {
      type: String,
      default: "ss",
    },
    showSecond: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.date = `${this.year}${this.dateSeparator}${this.month}${this.dateSeparator}${this.day}`;
    this.time = `${this.hour}${this.timeSeparator}${this.minute}${
      this.showSecond == true ? `${this.timeSeparator}${this.second}` : ""
    }`;
  },
  updated() {
    this.date = `${this.year}${this.dateSeparator}${this.month}${this.dateSeparator}${this.day}`;
    this.time = `${this.hour}${this.timeSeparator}${this.minute}${
      this.showSecond == true ? `${this.timeSeparator}${this.second}` : ""
    }`;
  },
  methods: {},
  mounted() {
    
  },
};
</script>

<style></style>
