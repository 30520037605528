import Vue from "vue";
let bankList = [
  // ansar
  {
    name: "انصار",
    symbol: "ansar",
    numbers: [627381],
  },

  // eghtesad-novin
  {
    name: "اقتصاد نوین",
    symbol: "eghtesad-novin",
    numbers: [627412],
  },

  // shahr
  {
    name: "شهر",
    symbol: "shahr",
    numbers: [502806, 504706],
  },

  // keshavarsi
  {
    name: "کشاورزی",
    symbol: "keshavarzi",
    numbers: [639217, 603770],
  },

  // iran-zamin
  {
    name: "ایران زمین",
    symbol: "iran-zamin",
    numbers: [505785],
  },

  // parsian
  {
    name: "پارسیان",
    symbol: "parsian",
    numbers: [627884, 639194, 622106],
  },

  // pasargad
  {
    name: "پاسارگاد",
    symbol: "pasargad",
    numbers: [502229, 639347],
  },

  // ayandeh
  {
    name: "آینده",
    symbol: "ayandeh",
    numbers: [636214],
  },

  // tejarat
  {
    name: "تجارت",
    symbol: "tejarat",
    numbers: [585983, 627353],
  },

  // tose-e-ta-avon
  {
    name: "توسعه تعاون",
    symbol: "tose-e-ta-avon",
    numbers: [502908],
  },

  // tose-e-saderat
  {
    name: "توسعه صادرات ایران",
    symbol: "tose-e-saderat",
    numbers: [207177, 627648],
  },

  // hekmat-iranian
  {
    name: "حکمت ایرانیان",
    symbol: "hekmat-iranian",
    numbers: [636949, 627648],
  },

  // dey
  {
    name: "دی",
    symbol: "dey",
    numbers: [502938],
  },

  // refah-kargaran
  {
    name: "رفاه کارگران",
    symbol: "refah-kargaran",
    numbers: [589463],
  },

  // saman
  {
    name: "سامان",
    symbol: "saman",
    numbers: [621986],
  },

  // sepah
  {
    name: "سپه",
    symbol: "sepah",
    numbers: [589210],
  },

  // sarmaye
  {
    name: "سرمایه",
    symbol: "sarmaye",
    numbers: [639607],
  },

  // sina
  {
    name: "سینا",
    symbol: "sina",
    numbers: [639346],
  },

  // saderat
  {
    name: "صادرات",
    symbol: "saderat",
    numbers: [603769],
  },

  // sanat-madan
  {
    name: "صنعت و معدن",
    symbol: "sanat-madan",
    numbers: [627961],
  },

  // gharzolhasane-mehr-iran
  {
    name: "قرض الحسنه مهر ایران",
    symbol: "gharzolhasane-mehr-iran",
    numbers: [606373],
  },

  // ghavamin
  {
    name: "قوامین",
    symbol: "ghavamin",
    numbers: [639599],
  },

  // kar-afarin
  {
    name: "کارآفرین",
    symbol: "kar-afarin",
    numbers: [502910, 627488],
  },

  // gardeshgari
  {
    name: "گردشگری",
    symbol: "gardeshgari",
    numbers: [505416],
  },

  // markazi
  {
    name: "مرکزی",
    symbol: "markazi",
    numbers: [636795],
  },

  // maskan
  {
    name: "مسکن",
    symbol: "maskan",
    numbers: [628023],
  },

  // melat
  {
    name: "ملت",
    symbol: "melat",
    numbers: [991975, 610433],
  },

  // meli
  {
    name: "ملی",
    symbol: "meli",
    numbers: [603799],
  },

  // mehr-eghtesad
  {
    name: "مهر اقتصاد",
    symbol: "mehr-eghtesad",
    numbers: [639370],
  },

  // post-bank-iran
  {
    name: "پست بانک ایران",
    symbol: "post-bank-iran",
    numbers: [627760],
  },

  // moasese-etebari-tose-e
  {
    name: "موسسه اعتباری توسعه",
    symbol: "moasese-etebari-tose-e",
    numbers: [628157],
  },

  // kosar
  {
    name: "کوثر",
    symbol: "kosar",
    numbers: [505801],
  },

  // moasese-etebari-melal
  {
    name: "مؤسسه اعتباری ملل (عسکریه سابق)",
    symbol: "moasese-etebari-melal",
    numbers: [606256],
  },

  // gharzol-hasane-resalat
  {
    name: "قرض الحسنه رسالت",
    symbol: "gharzol-hasane-resalat",
    numbers: [504172],
  },

  // khavar-miane
  {
    name: "خاورمیانه",
    symbol: "khavar-miane",
    numbers: [505809],
  },
];
const persianBank = {
  install(Vue, options) {
    let isString = false;
    let isStandardLength = false;
    let isFinded = false;
    Vue.prototype.$getBankDetail = (cardNumber) => {
      isFinded = false;
      if (cardNumber == null || cardNumber == undefined) {
        cardNumber = "";
      }
      if (typeof cardNumber != "string") {
        cardNumber = cardNumber.toString();
        isString = true;
      } else {
        cardNumber = cardNumber.replace(/\D/g, "");
      }
      if (cardNumber.length >= 6) {
        isStandardLength = true;
      } else {
        return {
          name: "",
          symbol: "exe",
        };
      }

      if ((isString, isStandardLength)) {
        let counter = 0;
        for (const bank of bankList) {
          counter++;
          for (const number of bank.numbers) {
            if (number == cardNumber.substring(6, -16)) {
              isFinded = true;
              return {
                name: bank.name,
                symbol: bank.symbol,
                numbers: [...bank.numbers],
              };
            }
          }
          if (bankList.length == counter) {
            if (isFinded == false) {
              return {
                name: "نا مشخص",
                symbol: "exe",
              };
            }
          }
        }
      }
    };
  },
};

Vue.use(persianBank);
