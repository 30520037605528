import Vue from "vue";
import coinHub from "../sockets/coinHub";

const coinHubPlugin = {
  install(Vue, options) {
    Vue.prototype.$COINHUB = coinHub;
  },
};


Vue.use(coinHubPlugin);
