import Vue from "vue";
import p2pOrderHub from "../sockets/p2pOrderHub";

const p2pOrderHubPlugin = {
  install(Vue, options) {
    Vue.prototype.$P2PORDERHUB = p2pOrderHub;
  },
};

Vue.use(p2pOrderHubPlugin);
