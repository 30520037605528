import router from "@/router";

import service from "@/service/service";
import { routes as adminRoutes, drawer as adminDrawer } from "@/router/routes/adminRoutes";
import { routes as employeeRoutes, drawer as employeeDrawer } from "@/router/routes/employeeRoutes";
import { getDrawer, getRoutes } from "@/router/routes/customerRoutes";

export default {
  state: () => ({
    info: {
      authorized: false,
      role: "guest",
      token: null,
    },
    guard: {
      routes: [],
      drawer: [],
      apis: [],
    },
    me: {},
    meLoading: true,
  }),
  mutations: {
    updateState(state, object, location) {
      for (const key in object) {
        state[key] = object[key];
      }
    },
    setMe(state, data) {
      state.me = data;
    },
    setMeLoading(state, data) {
      state.meLoading = data;
    },
    setToken(state, data) {
      state.info.token = data.token;
    },
    setInfoFromLocalStorage(state) {
      let info = localStorage.getItem("user-info");
      if (info != null) {
        state.info = JSON.parse(info);
      } else {
        localStorage.setItem("user-info", JSON.stringify(state.info));
      }
    },
    updateInfoInLocalStorage(state, refresh = true) {
      localStorage.setItem("user-info", JSON.stringify(state.info));
      if (refresh) {
        setTimeout(() => {
          location.reload();
        }, 1000);
      }
    },

    saveLogout(state) {
      localStorage.setItem("logout", JSON.stringify(true));
    },
    deleteLogout(state) {
      let logout = localStorage.getItem("logout");
      if (logout) {
        router.push("/login");
        localStorage.removeItem("logout");
      }
    },
    resetUser(state) {
      let guest = {
        authorized: false,
        role: "guest",
        token: null,
      };
      state.info = { ...guest };
      state.guard.routes = [];
      state.me = {};
    },

    setRole(state, data) {
      state.info.role = data;
    },
    setAuthorized(state, boolean) {
      state.info.authorized = boolean;
      if (boolean == true) {
        if (router.currentRoute?.meta?.types?.includes("unauthorized")) {
          router.push("/dashboard");
        }
      }
      if (boolean == false) {
        if (router.currentRoute.path != "/login") {
          router.push("/login");
        }
      }
    },
    setUserRoutes(state) { 
      // load routes based on user role (/me api)
      
      if (state.info.role == "admin") {
        state.guard.routes = [...adminRoutes];
        state.guard.drawer = [...adminDrawer];
      } 
      else if (state.info.role == "employee") {
        state.guard.routes = [...employeeRoutes];
        state.guard.drawer = [...employeeDrawer];
      }
      else if (state.info.role == "customer") {
        let customerRoutes = getRoutes(state.me.shopInfo);
        let customerDrawer = getDrawer(state.me.shopInfo);

        state.guard.routes = [...customerRoutes];
        state.guard.drawer = [...customerDrawer];
      } 
    },
    setUserApis(state, data) {
      state.guard.apis = [...data];
    },
  },
  actions: {
    updateMe(context) {
      context.commit("setMeLoading", true);

      return new Promise((resolve, reject) => {
        service
          .getMe()
          .then((res) => {
            context.commit("setMe", res.data.me);
            context.commit("setUserApis", res.data.routes);

            setTimeout(() => {
              context.commit("setRole", res.data.role);
              context.commit("setAuthorized", true);
              context.commit("updateInfoInLocalStorage", false);
              resolve();
            }, 100);
          })
          .catch((err) => {
            context.commit("setAuthorized", false);
            context.commit("updateInfoInLocalStorage", false);
            reject();
          })
          .finally(() => {
            setTimeout(() => {
              context.commit("setMeLoading", false);
            }, 500);
          });
      });
    },
    initial(context) {
      return new Promise((resolve, reject) => {
        context.commit("setInfoFromLocalStorage");
        context.commit("deleteLogout");
        setTimeout(() => {
          resolve();
        }, 50);
      });
    },
    logoutUser(context) {
      context.commit("resetUser");
      context.commit("saveLogout");
      context.commit("updateInfoInLocalStorage", true);
    },
    makeRoutes(context) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          context.commit("setUserRoutes");
          resolve();
        }, 50);
      });
    },
  },
  getters: {
    authorized(state) {
      return state.info.authorized;
    },
    getUserInfo(state) {
      return state.info;
    },
    getMe(state) {
      return state.me;
    },
    getDrawer(state) {
      return state.guard.drawer;
    },
  },
};
