import { returnAppConfig } from "@/util/getConfigs";
import { IS_PROD } from "../../util/globals";

returnAppConfig;

export default {
  state: () => ({
    isProduction: IS_PROD,
    version: {
      api: null,
      ui: null,
    },
    mainSite: returnAppConfig().mainSite,
    apiAddress: returnAppConfig().api,
    panelAddress : returnAppConfig().panel,
    cdn: returnAppConfig().cdn,
    siteKey: returnAppConfig().siteKey,
    copy:returnAppConfig().copy,
    config: returnAppConfig(),
    drawer: null,
    drawerImage: true,
    mini: false,
    miniBar: false,
    printMode: false,
    pageTitle: "home",
    userAvatar: "male-avatar.jpg",
    table: {
      loadingText: "درحال بارگزاری محتوا...",
      noDataText: "محتوایی برای نمایش نیست",
      itemsPerPage: 200,
      pagination: {
        label: "تعداد نمایش",
        totalVisible: 8,
        itemsPerPage: [10, 20, 50],
      },
    },
    timeFormat: {
      date: "YYYY/M/D",
      time: "H:m",
    },
  }),
  mutations: {},
  actions: {},
  getters: {
    getPanelAddress(state) {
      return state.panelAddress;
    },
    getMainSite(state) {
      return state.mainSite;
    },
    getApiAddress(state) {
      return state.apiAddress;
    },
    getCdn(state) {
      return state.cdn;
    },
    getCoin64Cdn(state) {
      return `${state.cdn}/coin_x64`;
    },
    getBank64Cdn(state) {
      return `${state.cdn}/banks_x64`;
    },
    getSiteKey(state) {
      return state.siteKey;
    },
    getConfig(state) {
      return state.config;
    },
  },
};
