import store from "@/store";

import Vue from "vue";
import VueRouter from "vue-router";
import { routes as globalRoutes } from "./routes/globalRoutes";

Vue.use(VueRouter);

const routes = [...globalRoutes];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  store.commit("setRouteLoading", true);

  if (to.meta.types) {
    if (to.meta.types.includes("unauthorized")) {
      if (store.getters.authorized == true) {
        next("/dashboard");
      } else {
        next();
      }
    } else if (to.meta.types.includes("authorized")) {
      if (store.getters.authorized == true) {
        next();
      } else {
        next("/login");
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to, from, next) => {
  store.commit("setRouteLoading", false);
});

export default router;
