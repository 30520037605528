<template>
  <animated-dollar
  :animation="animation"
  :number="number"
  :prefix="prefix"
  :increaseClass="increaseClass"
  :decreaseClass="decreaseClass"
  >
  </animated-dollar>
</template>

<script>
import animatedDollar from './animatedDollar.vue';

export default {
  name: "animatedToman",
  components:{
    animatedDollar
  },
  props: {
    number: {
      type: Number,
      default: 0,
    },
    prefix: {
      type: String,
      default: "ت",
    },
    animation: {
      type: Boolean,
      default: false,
    },
    increaseClass: {
      type: String,
      default: "",
    },
    decreaseClass: {
      type: String,
      default: "",
    },
  },
 
};
</script>
