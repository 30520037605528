import Vue from "vue";
import { IS_PROD } from "../util/globals";
export const loggerApi = (answer) => {
  let active = false; //IS_PROD == true ? false : true;
  let level = IS_PROD == false ? 4 : 1;
  let config = answer.config;
  let method = answer.config.method;
  let api = `${answer.config.baseURL} , ${answer.config.url}`;
  let status = answer.request.status;
  let response = status >= 200 && status <= 299 ? answer : answer.response;

  if (active == true) {
    if (level == 1) {
      console.log(`<---api called--->`);
      console.log(`${method} - ${api} - ${status}`);
      console.log(`<---api ended---/>`);
      console.log("-----");
    }
    if (level == 2) {
      console.log(`<---api called--->`);
      console.log(`${method} - ${api} - ${status}`);
      console.log(answer);
      console.log(`<---api ended---/>`);
      console.log("-----");
    }
    if (level == 3) {
      console.log(`<---api called--->`);
      console.log(`${method} - ${api} - ${status}`);
      console.log(status >= 200 && status <= 299 ? answer : response);
      console.log(`<---api ended---/>`);
      console.log("-----");
    }

    if (level == 4) {
      console.log(`<---api called--->`);
      console.log(`method : ${method}`);
      console.log(`status : ${status}`);
      console.log(`api : ${api}`);
      console.log(`response :`);
      console.log(status >= 200 && status <= 299 ? answer : response);
      console.log(`<---api ended---/>`);
      console.log("-----");
    }
  }
  if (active == false) {
  }
};

const logger = {
  install(Vue, options) {
    Vue.prototype.$loggerApi = (method, api, type, response) => {
      return loggerApi(method, api, type, response);
    };
  },
};

Vue.use(logger);
